/* eslint-disable react/no-unknown-property */
import * as React from 'react';
import { Node } from 'slate';
import axios from 'axios';
import { isNil, takeRight, uniqBy } from 'lodash';
import { observer } from 'mobx-react-lite';
import { flow, Instance, SnapshotIn, types as t } from 'mobx-state-tree';
import { useCreateStore, useProvider, useStore } from 'mobx-store-provider';
import styled from 'styled-components';

import { apis } from '@doltech/core/lib/api/api.config';
import { environment } from '@doltech/core/lib/environment/environment';
import { isJson } from '@doltech/utils/lib/object';
import { isNotEmpty, slugify } from '@doltech/utils/lib/text';
import { apiPublicUrls } from '@doltech/core/lib/api/api.public-urls';

const SUPPORTED_MIME_TYPES = ['audio/ogg', 'audio/x-m4a', 'audio/mpeg'];

const cacheLimit = 50;
const mountAudioLimit = 20; // should keep it low number and separate from cacheLimit (cacheLimit can have higher number)

const getSlateNodeText = (content, enableTrim = false) => {
  let text = '';
  const node = typeof content === 'string' ? JSON.parse(content) : content;
  text += `${node.map((n) => Node.string(n)).join(' ')} `;
  return enableTrim ? text.trim() : text;
};

export const getAudioTextToSpeechByRawText = async (text: string, voiceName?: string) => {
  const response = await axios.post<any>(environment.REACT_APP_TEXT_TO_SPEECH_API, {
    text,
    voiceName,
  });
  return response.data;
};

const getAudioTextToSpeechByPhonetics = async (phonetics: string) => {
  const response = await apis.publicTTS.post(apiPublicUrls.tts.TTS_PHONETIC, [
    { word: slugify({ text: phonetics }).replace(/\//, ''), ipa: phonetics },
  ]);
  return response.data;
};

interface AudioPlayOptions {
  /** play back speed for audio */
  speed?: number;

  /** audio current time in seconds */
  currentTimeInSeconds?: number;

  /* seek to 0 when click play */
  resetOnPlay?: boolean;

  /* google voice name when play audio */
  voiceName?: string;
}

export const SimpleAudioPlayInBackgroundStore = t
  .model('DolEditorStore', {
    url: t.maybeNull<any>(t.string),
    playing: t.optional(t.boolean, false),
    playbackRate: t.optional(t.number, 1),
    state: t.optional<any>(t.string, 'done'),
    playingValue: t.maybeNull<any>(t.string),
    audioTimeInSeconds: t.optional(t.number, 0),
    durationInSeconds: t.optional(t.number, 0),
  })
  .volatile(() => ({
    textCache: [],
    audioRef: null,
  }))
  .views((self) => ({
    findAudioCacheByText(text, voiceName = '') {
      return self.textCache.find((item) => item.text === text && item.voice === voiceName);
    },
  }))
  .actions((self) => ({
    reset() {
      self.audioRef.currentTime = 0;
    },
  }))
  .actions((self) => ({
    play(url, options: AudioPlayOptions = {}) {
      self.url = url;

      for (const eachSourceEl of self.audioRef.children) {
        eachSourceEl.src = url;
      }

      self.audioRef.load();

      self.audioRef.playbackRate = options?.speed || self.playbackRate;
      self.audioRef.currentTime = options?.currentTimeInSeconds || self.audioTimeInSeconds;

      if ((isNil(options?.currentTimeInSeconds) || options.resetOnPlay) && self.audioRef) {
        self.reset();
      }

      self.audioRef.play();
    },
    pause() {
      self.audioRef.pause();
    },
    stop() {
      self.audioRef.currentTime = self.durationInSeconds;
    },
    ended() {
      if (self.audioRef && self.playing) {
        self.reset();
        self.url = null;

        for (const eachSourceEl of self.audioRef.children) {
          eachSourceEl.src = '';
        }
      }
      self.playing = false;
      self.audioRef.load();
    },

    addToCache({ text, dataUrl, voiceName = '' }) {
      self.textCache.push({ text, dataUrl, voiceName });
      self.textCache = takeRight(uniqBy(`${self.textCache}${voiceName}`, 'text'), cacheLimit);
    },
  }))
  .actions((self) => ({
    setAudioRef(audioRef) {
      self.audioRef = audioRef;
    },
    setPlaying(playing) {
      self.playing = playing;
    },
    mountAudioTexts: flow(function* fetchAudioByTexts(
      texts: string[],
      options: AudioPlayOptions = {}
    ) {
      const limitTexts = texts.slice(0, mountAudioLimit);
      try {
        const responses = yield Promise.all(
          limitTexts.map((text) => {
            return getAudioTextToSpeechByRawText(text, options?.voiceName);
          })
        );

        for (const [index, res] of responses.entries()) {
          const { audioUrl } = res.data;

          // teardown
          self.addToCache({ text: limitTexts[index], dataUrl: audioUrl });
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Failed to fetch audio texts', error);
      }
    }),
    playByText: flow(function* fetchAudioByText(text: string, options: AudioPlayOptions = {}) {
      if (!isNotEmpty(text)) {
        return;
      }
      self.state = 'pending';
      self.playingValue = text;
      const existedAudio = self.findAudioCacheByText(text, options.voiceName);
      if (existedAudio) {
        self.play(existedAudio.dataUrl, options);
        self.state = 'done';

        return;
      }

      try {
        const res = yield getAudioTextToSpeechByRawText(text, options?.voiceName);
        const { audioUrl } = res.data;
        self.play(audioUrl, options);
        self.state = 'done';

        // teardown
        self.addToCache({ text, dataUrl: audioUrl, voiceName: options.voiceName });
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Failed to fetch projects', error);
        self.state = 'error';
      }
    }),
    playByPhonetics: flow(function* fetchAudioByPhonetics(
      phonetic: string,
      options: AudioPlayOptions = {}
    ) {
      self.state = 'pending';
      self.playingValue = phonetic;
      const existedAudio = self.findAudioCacheByText(phonetic);
      if (existedAudio) {
        self.play(existedAudio.dataUrl, options);
        self.state = 'done';
        return;
      }

      try {
        const res = yield getAudioTextToSpeechByPhonetics(phonetic);
        const { file } = res[0];
        self.play(file.url, options);
        self.state = 'done';

        // teardown
        self.addToCache({ text: phonetic, dataUrl: file.url });
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Failed to fetch projects', error);
        self.state = 'error';
      }
    }),
  }))
  .views(() => ({
    getEditorText(editorValue) {
      let text = editorValue;
      if (typeof editorValue === 'object') {
        text = getSlateNodeText(editorValue);
      }
      if (isNaN(editorValue) && isJson(editorValue)) {
        const slateNode = JSON.parse(editorValue);
        text = getSlateNodeText(slateNode);
      }
      return text;
    },
  }))
  .actions((self) => ({
    playByEditorText(editorValue, options: AudioPlayOptions = {}) {
      self.playByText(self.getEditorText(editorValue), options);
    },
    setAudioTimeInSeconds(audioTimeInSeconds) {
      self.audioTimeInSeconds = audioTimeInSeconds;
    },
    setDurationInSeconds(durationInSeconds) {
      self.durationInSeconds = durationInSeconds;
    },
  }));

export const useSimpleAudioPlayInBackgroundStore = () => {
  return useStore(SimpleAudioPlayInBackgroundStore);
};

const BackgroundAudioPlayerMain = styled.div`
  display: none;
`;

const BackgroundAudioPlayer = observer(() => {
  const store = useSimpleAudioPlayInBackgroundStore();

  return (
    <BackgroundAudioPlayerMain>
      <audio
        ref={(ref) => store.setAudioRef(ref)}
        preload="auto"
        playsInline
        onPlaying={() => store.setPlaying(true)}
        onPause={() => store.setPlaying(false)}
        onEnded={store.ended}
        onDurationChange={(event: any) => store.setDurationInSeconds(event.target.duration)}
        onTimeUpdate={(event: any) => store.setAudioTimeInSeconds(event.target.currentTime)}
      >
        {SUPPORTED_MIME_TYPES.map((mimeType, typeIndex) => {
          return (
            <source
              key={typeIndex}
              id={`audio-source-${typeIndex + 1}`}
              src=""
              type={mimeType}
            ></source>
          );
        })}
        Your browser does not support the audio format.
      </audio>
    </BackgroundAudioPlayerMain>
  );
});

export const withSimpleAudioPlayInBackground = (Component: any) => (props: any) => {
  const audioPlayInBackgroundCreateStore = useCreateStore(SimpleAudioPlayInBackgroundStore, {});
  const AudioPlayInBackgroundProvider = useProvider(SimpleAudioPlayInBackgroundStore);

  return (
    <AudioPlayInBackgroundProvider value={audioPlayInBackgroundCreateStore}>
      <BackgroundAudioPlayer />
      <Component {...props} />
    </AudioPlayInBackgroundProvider>
  );
};

export interface SimpleAudioPlayInBackgroundStoreSnapshotIn
  extends SnapshotIn<typeof SimpleAudioPlayInBackgroundStore> {}
export interface SimpleAudioPlayInBackgroundStoreInstance
  extends Instance<typeof SimpleAudioPlayInBackgroundStore> {}
