import '@doltech/ui/lib/antd/dol.theme.global.less';
import { withAppScroll } from '@doltech/core/lib/appScroll/withAppScroll';
import { bootstrapAuthManager } from '@doltech/core/lib/auth/bootstrapAuthManager';
import { withGlobalUserAuthInfo } from '@doltech/core/lib/auth/withGlobalUserAuthInfo';
import { withDndHtml5Backend } from '@doltech/core/lib/hooks/withDndHtml5Backend';
import { withReactQueryConfig } from '@doltech/core/lib/hooks/withReactQueryConfig';
import { withDolSystemDesignThemeProvider } from '@doltech/ui/lib/figma/Common/theme';
import { useTrackingLastUrlAndAutoScrollToTop } from '@doltech/ui/lib/hocs/withDolSetup';
import { withGlobalModal } from '@doltech/ui/lib/hocs/withGlobalModal';
import { withNetworkDetect } from '@doltech/ui/lib/hocs/withNetworkDetect';
import { useRouterQuery } from '@doltech/ui/lib/hooks/useRouterQuery.hook';
import { compose } from '@doltech/utils/lib/compose';
import { isBrowser } from '@doltech/utils/lib/platform';
import { pageViewEvent, pageViewEventPixelSDK } from '@doltech/utils/lib/user-pixel';

import { v4 as uuidv4 } from 'uuid';
import { useRouter } from 'next/router';
import { Spin } from 'antd';
import Head from 'next/head';
import * as React from 'react';

import { withLMSStudentAuth } from '../hocs/withLMSStudentAuth';
import { withGlobalModalPortal } from '@doltech/ui/lib/hocs/withGlobalModalPortal';
import { withPreventInAppBrowserView } from '@doltech/core/lib/auth/withPreventInAppBrowserView';
import { withSimpleAudioPlayInBackground } from '@doltech/ui/lib/hooks/simple-audio-play-in-background.store';

bootstrapAuthManager('lmsStudent');
// trigger build 2
const pixelCode = ` !function (w, d, t) {
  w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie","holdConsent","revokeConsent","grantConsent"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(
var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var r="https://analytics.tiktok.com/i18n/pixel/events.js",o=n&&n.partner;ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=r,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};n=document.createElement("script")
;n.type="text/javascript",n.async=!0,n.src=r+"?sdkid="+e+"&lib="+t;e=document.getElementsByTagName("script")[0];e.parentNode.insertBefore(n,e)};


  ttq.load('CSLDE0JC77UCS5RP5DRG');
}(window, document, 'ttq');`;

const MyApp = ({ Component, pageProps }) => {
  const query = useRouterQuery();
  useTrackingLastUrlAndAutoScrollToTop();

  const router = useRouter();

  React.useEffect(() => {
    function loadScriptIfNeeded() {
      const element = document.querySelector('#pixel-script');
      if (!element) {
        const script = document.createElement('script');
        script.id = 'pixel-script';
        script.type = 'text/javascript';
        script.text = pixelCode;
        script.async = true;
        document.head.appendChild(script);
      }
    }

    function handleRouteChange() {
      loadScriptIfNeeded();
      const currentuser = isBrowser() && localStorage.getItem('auth.currentuser.custom_claim');
      const userMetadata = JSON.parse(currentuser)?.userMetadata;
      const eventId = uuidv4();
      pageViewEventPixelSDK(eventId);
      pageViewEvent(eventId, 'superlms', userMetadata?.email, userMetadata?.phone);
    }

    loadScriptIfNeeded();
    handleRouteChange();

    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  if (!query) return <Spin />;
  return (
    <>
      <Head>
        <title>DOL English - super LMS</title>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=5, minimum-scale=1, viewport-fit=cover"
        />
        <meta property="og:title" content="DOL English- super LMS" />
        <meta property="og:site_name" content="super LMS" />
        <meta property="og:url" content="https://superlms.dolenglish.vn" />
        <meta property="og:description" content="DOL English - super LMS" />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta property="twitter:domain" content="superlms.dolenglish.vn" />
        <meta property="twitter:url" content="https://superlms.dolenglish.vn" />
        <meta name="twitter:title" content="DOL English- super LMS" />
        <meta name="twitter:description" content="DOL English - super LMS" />
        <meta
          name="twitter:image"
          content="https://media.dolenglish.vn/directus-upload/54a9fb50-21e1-4e9b-90cc-611a4ab2c41c.jpg"
        />
        <meta property="fb:app_id" content="722752771433071" />
      </Head>
      <Component {...{ query, ...pageProps }} />
    </>
  );
};
// test
export default compose(
  withDndHtml5Backend,
  withReactQueryConfig,
  withDolSystemDesignThemeProvider,
  withAppScroll({
    getAppScrollElement: () => document.getElementById('app-root'),
    getAppContainer: () => document.getElementById('app-root'),
  }),
  withGlobalUserAuthInfo,
  withLMSStudentAuth({
    publicRoutes: [
      '/',
      '/login',
      '/callback',
      '/forbidden',
      '/pages-debug',
      '/classes/*',
      '/admin/engineer',
      '/my-classes-trial/*',
      '/sheet/final-test/public/*',
      '/free-trial/*',
    ],
  }),
  withGlobalModal,
  withGlobalModalPortal,
  withNetworkDetect({}),
  withSimpleAudioPlayInBackground,
  withPreventInAppBrowserView
)(MyApp);
