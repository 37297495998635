import * as React from 'react';
import styled from 'styled-components';
import cl from 'classnames';

import { SpokeLoading } from './SpokeLoading';

const Main = styled.div<{ $minHeightFullScreen?: boolean; $minHeight?: number }>`
  position: relative;
  min-height: ${(p) => (p.$minHeightFullScreen ? '100vh' : `${p.$minHeight}px` || 'auto')};
  height: ${(p) => (p.$minHeightFullScreen ? '100%' : 'auto')};
  .overlay-spoke {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 500;
  }
  .text {
    font-size: 20px;
    font-weight: 600;
    color: #d14242;
  }

  &.with-overlay {
    .overlay-spoke {
      background-color: #fff;
      opacity: 0.5;
    }
  }

  &.fixed {
    .overlay-spoke {
      position: fixed;
    }
  }
`;

interface SpokeSpinWrapperProps {
  text?: any;
  children?: any;
  spinning?: boolean;
  minHeightFullScreen?: boolean;
  minHeight?: number;
  spinSize?: number;
  withOverlay?: boolean;
  fixed?: boolean;
}

export const SpokeSpinWrapper = ({
  text,
  children,
  spinning,
  minHeightFullScreen,
  minHeight,
  spinSize = 32,
  withOverlay = false,
  fixed = false,
}: SpokeSpinWrapperProps) => {
  if (!spinning) {
    return children;
  }
  return (
    <Main
      className={cl({ 'with-overlay': withOverlay, fixed })}
      $minHeight={minHeight}
      $minHeightFullScreen={minHeightFullScreen}
    >
      <div className="overlay-spoke">
        <SpokeLoading width={spinSize} height={spinSize} color="#D14242" />
        <div className="text">{text}</div>
      </div>
      {children}
    </Main>
  );
};
