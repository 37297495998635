import { ApisauceConfig, create } from 'apisauce';
import axios from 'axios';
import getConfig from 'next/config';
import * as qs from 'qs';

import { useAuthManager } from '../auth/authManager';
import { environment } from '../environment/environment';
import { transformPathParam } from './api-sauce';
import { curlFromAxiosRequest } from './axios-curlirize';

const { publicRuntimeConfig } = getConfig();

const { log } = console;

const defaultHeaders = {
  'Access-Control-Allow-Origin': '*',
  'Content-Type': 'application/json',
};

const kafkaRestHeaders = {
  'Access-Control-Allow-Origin': '*',
  'Content-Type': 'application/vnd.kafka.json.v2+json',
};
const defaults = (...args) => args.reverse().reduce((acc, obj) => ({ ...acc, ...obj }), {});

const defaultConfigs = {
  headers: defaultHeaders,
  timeout: 30000,
  paramsSerializer: (params) => {
    return qs.stringify(params, { arrayFormat: 'repeat', skipNulls: true, encode: true });
  },
};

const trimEnd = (str, c = '\\s') => str.replace(new RegExp(`^(.*?)([${c}]*)$`), '$1');

export const proxyBaseUrl = (baseUrl) => {
  // Don't proxy base url when build
  if (['INTEGRATION', 'PRODUCTION'].includes(process.env.NODE_ENV) === false) {
    if (typeof window === 'undefined' || !process.env.NEXT_PUBLIC_PROXY_ENABLED) {
      return baseUrl;
    }
    // Start proxy
    if (baseUrl) {
      const apiUrl = new URL(baseUrl);
      return `${trimEnd(window.location.origin, '/')}${apiUrl.pathname}`;
    }
  }
  return baseUrl;
};

interface Props {
  ignoreHeader?: boolean;
}

export const setup = (config: ApisauceConfig, extraProps: Props = { ignoreHeader: false }) => {
  const { ignoreHeader } = extraProps;
  const axiosInstance: any = axios.create({
    baseURL: proxyBaseUrl(config.baseURL),
    ...defaultConfigs,
    ...config,
  });

  const client = create({
    axiosInstance,
    ...defaultConfigs,
    ...config,
  });

  client.addRequestTransform((request) => {
    transformPathParam(request);
    const curlCommand = curlFromAxiosRequest({ ...request, baseURL: proxyBaseUrl(config.baseURL) });
    // Don't curlirize in web browser
    if (typeof window === 'undefined') {
      log('');
      log('=======[cUrl Request]============');
      log(curlCommand);
      log('===============================');
      log('');
    }
  });

  client.addAsyncRequestTransform(async (request) => {
    const authManager = useAuthManager();

    if (Boolean(client.headers.Authorization) === false && !ignoreHeader && authManager) {
      const isLoggedIn = await authManager.isLoggedIn();
      if (!(request.headers.authorization || request.headers.Authorization)) {
        let token = null;
        if (isLoggedIn) {
          token = `Bearer ${await authManager.getAccessToken()}`;
        }
        if (process.env.STORYBOOK_ENABLED) {
          const userInfo = JSON.parse(
            window.localStorage.getItem(environment.REACT_AUTH_LOCAL_STORAGE_KEY)
          );
          token = `Bearer ${userInfo.accessToken}`;
        }
        request.headers.Authorization = token;
        delete request.headers.authorization;
      }
      request.headers = defaults(request.headers, defaultHeaders);
    }
    if (typeof window !== 'undefined' && localStorage != null) {
      const userIdFromLocalStorage = localStorage.getItem('X_USER_ID');
      if (userIdFromLocalStorage != null) {
        request.headers['X-UserId'] = userIdFromLocalStorage;
      }

      const contactIdFromLocalStorage = localStorage.getItem('X_CONTACT_ID');
      if (contactIdFromLocalStorage != null) {
        request.headers['X-ContactId'] = contactIdFromLocalStorage;
      }
    }
    if (typeof window !== 'undefined' && sessionStorage != null) {
      const trialStudentkeyFromSession = sessionStorage.getItem('X_TRIAL_STUDENT_KEY');
      if (trialStudentkeyFromSession != null) {
        request.headers['X_COURSE_TRIAL_ID'] = trialStudentkeyFromSession;
      }

      const trialHsContactIdFromSession = sessionStorage.getItem('X_TRIAL_HS_CONTACT_ID');
      if (trialHsContactIdFromSession != null) {
        request.headers['X_TRIAL_HS_CONTACT_ID'] = trialHsContactIdFromSession;
      }
    }
    if (!request.headers.Authorization) {
      delete request.headers.Authorization;
    }
  });

  client.addAsyncResponseTransform(async (response) => {
    const authManager = useAuthManager();
    const retryAuth = async () => {
      // retry
      await authManager.getAccessToken(true);
      const accessToken = (authManager?.auth?.currentUser as any)?.accessToken;
      if (accessToken) {
        // create another client to make sure it will not loop the api call
        const client2 = create({
          axiosInstance,
          ...defaultConfigs,
          ...config,
        });
        client.setHeader('Authorization', `Bearer ${accessToken}`);
        client2.setHeader('Authorization', `Bearer ${accessToken}`);

        if (typeof window !== 'undefined') {
          window.dispatchEvent(new CustomEvent('refreshToken', { detail: {} }));
        }
        response.data = await client2.any(response.config);
      }
      throw new Error('Token not found');
    };
    if (response.status === 401) {
      const authErrorCode = response.headers['x-authentication-code'];
      if (!authErrorCode || authErrorCode === 'INVALID_TOKEN') {
        try {
          await retryAuth();
          // eslint-disable-next-line no-empty
        } catch (ex) {}
        return;
      }
      if (authErrorCode === 'TOKEN_NOT_FOUND') {
        try {
          await retryAuth();
          return;
          // eslint-disable-next-line no-empty
        } catch (ex) {}
      }

      await authManager.logout();
    }
    if (!response.ok) {
      throw response.originalError;
    }
  });

  return client;
};

export const setupKafkaRest = (config: ApisauceConfig) => {
  const axiosInstance: any = axios.create({
    baseURL: proxyBaseUrl(config.baseURL),
    ...defaultConfigs,
  });

  const client = create({
    axiosInstance,
    ...defaultConfigs,
    ...config,
  });

  client.addAsyncRequestTransform(async (request) => {
    request.headers = kafkaRestHeaders;
  });
  return client;
};

export const setupNoAuthInstance = () => {
  const axiosInstance: any = axios.create({
    ...defaultConfigs,
  });
  return create({
    axiosInstance,
    ...(defaultConfigs as ApisauceConfig),
  });
};

export const apis = {
  onlineTest: setup({
    baseURL: environment.REACT_APP_ONLINE_TEST_API_HOSTNAME,
  }),
  onlineTestChina: setup({
    baseURL: environment.REACT_APP_ONLINE_TEST_CHINA_API_HOSTNAME,
  }),
  onlineTestGlobal: setup({
    baseURL: environment.REACT_APP_ONLINE_TEST_GLOBAL_API_HOSTNAME,
  }),
  s3: setup({
    baseURL: environment.REACT_S3_SERVICE_API_HOSTNAME,
    timeout: 70000,
  }),
  pageManagement: setup({
    baseURL: environment.REACT_APP_PAGE_MANAGEMENT_API_HOSTNAME,
  }),
  pageManagementChina: setup({
    baseURL: environment.REACT_APP_PAGE_MANAGEMENT_CHINA_API_HOSTNAME,
  }),
  pageManagementGlobal: setup({
    baseURL: environment.REACT_APP_PAGE_MANAGEMENT_GLOBAL_API_HOSTNAME,
  }),
  k12Service: setup({
    baseURL: environment.REACT_APP_K12_SERVICE_API_HOSTNAME,
  }),
  k12ResMng: setup({
    baseURL: environment.REACT_APP_K12_RESOURCE_MNG_API_HOSTNAME,
  }),
  verifyToken: setup({
    baseURL: environment.REACT_VERIFY_APP_API_HOSTNAME,
  }),
  publicService: setup({
    baseURL: environment.REACT_PUBLIC_SERVICE_API_HOSTNAME,
  }),
  userService: setup({
    baseURL: environment.REACT_APP_USER_SERVICE_API_HOSTNAME,
  }),
  goUserService: setup({
    baseURL: environment.REACT_GO_USER_API_HOSTNAME,
  }),

  searchService: setup({
    baseURL: environment.REACT_APP_SEARCH_ENGINE_API_HOSTNAME,
  }),

  activityService: setup({
    baseURL: environment.REACT_APP_ACTIVITY_API_HOSTNAME,
  }),
  kafkaRest: setupKafkaRest({
    baseURL: environment.REACT_APP_KAFKA_REST_API_HOSTNAME,
  }),
  authorization: setup({
    baseURL: environment.REACT_APP_AUTHORIZATION_API_HOSTNAME,
  }),
  cms: setup({
    baseURL: environment.REACT_APP_CMS_API_HOSTNAME,
  }),
  cmsDirectus: setup(
    {
      baseURL: environment.REACT_APP_CMS_DIRECTUS_API_HOSTNAME,
    },
    { ignoreHeader: true }
  ),
  cmsM2M: setup({
    baseURL: publicRuntimeConfig.isRunTimeOnDocker
      ? environment.REACT_APP_M2M_CMS_API_HOSTNAME
      : environment.REACT_APP_CMS_API_HOSTNAME,
  }),
  searchServiceM2M: setup({
    baseURL: publicRuntimeConfig.isRunTimeOnDocker
      ? environment.REACT_APP_M2M_SEARCH_SERVICE_API_HOSTNAME
      : environment.REACT_APP_SEARCH_SERVICE_API_HOSTNAME,
  }),
  onlineTestM2M: setup({
    baseURL: publicRuntimeConfig.isRunTimeOnDocker
      ? environment.REACT_APP_M2M_ONLINE_TEST_API_HOSTNAME
      : environment.REACT_APP_ONLINE_TEST_API_HOSTNAME,
  }),
  pageManagementM2M: setup({
    baseURL: publicRuntimeConfig.isRunTimeOnDocker
      ? environment.REACT_APP_M2M_PAGE_MANAGEMENT_API_HOSTNAME
      : environment.REACT_APP_PAGE_MANAGEMENT_API_HOSTNAME,
  }),
  grammarM2M: setup({
    baseURL: environment.REACT_APP_PAGE_MANAGEMENT_API_HOSTNAME,
  }),
  corpWebM2M: setup({
    baseURL: publicRuntimeConfig.isRunTimeOnDocker
      ? environment.REACT_APP_M2M_DOL_CORP_WEBSITE_API_HOST_NAME
      : environment.REACT_APP_DOL_CORP_WEBSITE_API_HOST_NAME,
  }),
  entranceTestM2m: setup({
    baseURL: publicRuntimeConfig.isRunTimeOnDocker
      ? environment.REACT_APP_M2M_ENTRANCE_TEST_API_HOSTNAME
      : environment.REACT_APP_ENTRANCE_TEST_API_HOSTNAME,
  }),
  offlineCourseM2M: setup({
    baseURL: publicRuntimeConfig.isRunTimeOnDocker
      ? environment.REACT_APP_M2M_OFFLINE_COURSE_API_HOSTNAME
      : environment.REACT_APP_COURSE_MANAGEMENT_API_HOSTNAME,
  }),
  corpWeb: setup({
    baseURL: environment.REACT_APP_DOL_CORP_WEBSITE_API_HOST_NAME,
  }),
  membership: setup({
    baseURL: environment.REACT_APP_MEMBERSHIP_DOMAIN_NAME,
  }),
  exercise: setup({
    baseURL: environment.REACT_APP_EXERCISE_API_HOST_NAME,
  }),
  exerciseV2: setup({
    baseURL: environment.REACT_APP_EXERCISE_V2_API_HOST_NAME,
  }),
  cognitiveService: setup({
    baseURL: environment.REACT_APP_COGNITIVE_SERVICE_API_HOST_NAME,
  }),
  assignment: setup({
    baseURL: environment.REACT_APP_ASSIGNMENT_API_HOST_NAME,
  }),
  university: setup({
    baseURL: environment.REACT_APP_UNIVERSITY_API_HOSTNAME,
  }),
  courseService: setup({
    baseURL: environment.REACT_APP_COURSE_MANAGEMENT_API_HOSTNAME,
  }),
  syllabusService: setup({
    baseURL: environment.REACT_APP_SYLLABUS_MANAGEMENT_API_HOSTNAME,
  }),
  entranceTest: setup({
    baseURL: environment.REACT_APP_ENTRANCE_TEST_API_HOSTNAME,
  }),
  markingForm: setup({
    baseURL: environment.REACT_APP_MARKING_FORM_API_HOSTNAME,
  }),
  quizTest: setup({
    baseURL: environment.REACT_APP_QUIZ_TEST_API_HOSTNAME,
  }),
  midFinalTest: setup({
    baseURL: environment.REACT_APP_MID_FINAL_TEST_API_HOSTNAME,
  }),
  aiMockTest: setup({
    baseURL: environment.REACT_APP_AI_MOCK_TEST_API_HOST_NAME,
  }),
  vocabService: setup({
    baseURL: environment.REACT_APP_VOCAB_API_HOSTNAME,
  }),
  roadmapService: setup({
    baseURL: environment.REACT_APP_ROADMAP_API_HOST_NAME,
  }),
  dictationService: setup({
    baseURL: environment.REACT_APP_DICTATION_API_HOSTNAME,
  }),
  googleCloudAudio: setup({
    baseURL: environment.REACT_GOOGLE_CLOUD_AUDIO_SERVICE,
  }),
  midFinalTestM2m: setup({
    baseURL: publicRuntimeConfig.isRunTimeOnDocker
      ? environment.REACT_APP_M2M_MID_FINAL_TEST_API_HOSTNAME
      : environment.REACT_APP_MID_FINAL_TEST_API_HOSTNAME,
  }),
  cloudFn: setup({
    baseURL: '',
  }),
  courseAppSheet: setup({
    baseURL: environment.REACT_APP_COURSE_APP_SHEET_API_HOSTNAME,
  }),
  courseAppSheetM2m: setup({
    baseURL: publicRuntimeConfig.isRunTimeOnDocker
      ? environment.REACT_APP_M2M_COURSE_APP_SHEET_API_HOSTNAME
      : environment.REACT_APP_COURSE_APP_SHEET_API_HOSTNAME,
  }),
  videoService: setup({
    baseURL: environment.REACT_APP_VIDEO_SERVICE_API_HOSTNAME,
  }),
  publicPageManagement: setup({
    baseURL: environment.REACT_PUBLIC_PAGE_MANAGEMENT_SERVICE_API_HOSTNAME,
  }),
  publicSearchTransform: setup(
    {
      baseURL: environment.REACT_PUBLIC_SEARCH_TRANSFORM_SERVICE_API_HOSTNAME,
    },
    { ignoreHeader: true }
  ),
  publicTTS: setup(
    {
      baseURL: environment.REACT_PUBLIC_TTS_SERVICE_API_HOSTNAME,
    },
    { ignoreHeader: true }
  ),
  publicOnlineTestV2: setup(
    {
      baseURL: environment.REACT_PUBLIC_ONLINE_TEST_V2_SERVICE_API_HOSTNAME,
    },
    { ignoreHeader: true }
  ),
  publicMembership: setup(
    {
      baseURL: environment.REACT_PUBLIC_MEMBERSHIP_SERVICE_API_HOSTNAME,
    },
    { ignoreHeader: true }
  ),
  publicK12Service: setup(
    {
      baseURL: environment.REACT_PUBLIC_K12_SERVICE_API_HOSTNAME,
    },
    { ignoreHeader: true }
  ),
  publicUser: setup(
    {
      baseURL: environment.REACT_PUBLIC_USER_SERVICE_API_HOSTNAME,
    },
    { ignoreHeader: true }
  ),
  publicMidFinalTest: setup({
    baseURL: environment.REACT_PUBLIC_MID_FINAL_TEST_API_HOSTNAME,
  }),
  publicEntranceTest: setup(
    {
      baseURL: environment.REACT_PUBLIC_ENTRANCE_TEST_API_HOSTNAME,
    },
    { ignoreHeader: true }
  ),
  publicSatEntranceFinal: setup(
    {
      baseURL: environment.REACT_PUBLIC_SAT_ENTRANCE_FINAL_API_HOSTNAME,
    },
    { ignoreHeader: true }
  ),
  publicSat: setup(
    {
      baseURL: environment.REACT_PUBLIC_SAT_API_HOSTNAME,
    },
    { ignoreHeader: true }
  ),
  publicCourseAppSheet: setup({
    baseURL: environment.REACT_PUBLIC_COURSE_APP_SHEET_API_HOSTNAME,
  }),
  publicOfflineCourse: setup({
    baseURL: environment.REACT_PUBLIC_OFFLINE_COURSE_API_HOSTNAME,
  }),
  grammar: setup({
    baseURL: environment.REACT_APP_GRAMMAR_API_HOSTNAME,
  }),
  seoRedirect: setup({
    baseURL: environment.REACT_APP_SEO_REDIRECT_API_HOSTNAME,
  }),
  publicSeoRedirect: setup({
    baseURL: environment.REACT_PUBLIC_SEO_REDIRECT_API_HOSTNAME,
  }),
  publicGoUserService: setup({
    baseURL: environment.REACT_PUBLIC_GO_USER_API_HOSTNAME,
  }),
  publicAssignment: setup({
    baseURL: environment.REACT_PUBLIC_ASSIGNMENT_API_HOSTNAME,
  }),
  publicDictationService: setup({
    baseURL: environment.REACT_PUBLIC_APP_DICTATION_API_HOSTNAME,
  }),
  publicVocabService: setup({
    baseURL: environment.REACT_PUBLIC_APP_VOCAB_API_HOSTNAME,
  }),
  materialService: setup({
    baseURL: environment.REACT_APP_MATERIAL_API_HOST_NAME,
  }),
  noAuth: setupNoAuthInstance(),
  toeic: setup({
    baseURL: environment.REACT_APP_TOEIC_API_HOSTNAME,
  }),
  documentHistory: setup({
    baseURL: environment.REACT_APP_DOCUMENT_HISTORY_API_HOSTNAME,
  }),
  gotenberg: setup({
    baseURL: environment.REACT_APP_GOTENBERG_API_HOSTNAME,
  }),
  toeicAssignment: setup({
    baseURL: environment.REACT_APP_TOEIC_ASSIGNMENT_API_HOSTNAME,
  }),
  englishUtils: setup({
    baseURL: environment.REACT_APP_ENGLISH_UTILS_API_HOSTNAME,
  }),
};
