import { useAuthManager } from '@doltech/core/lib/auth/authManager';
import { isMatch } from 'matcher';
import { useRouter } from 'next/router';
import * as React from 'react';
import { SpokeSpinWrapper } from '@doltech/core/lib/auth/SpokeSpinWrapper';

interface WithLoadingProps {
  isLoading: boolean;
}

interface WithLMSStudentAuthOptions {
  publicRoutes: string[];
}

export const withLMSStudentAuth =
  (options: WithLMSStudentAuthOptions) =>
  (AuthComponent: React.ComponentType<any | string>) =>
  (props: WithLoadingProps) => {
    const authManager = useAuthManager();
    const router = useRouter();
    const isHandleAuth = React.useRef(false);
    const isPublicRoute = options?.publicRoutes.some((url) => isMatch(router.pathname, url));
    const [isLoading, setLoading] = React.useState(false);

    const isHomePage = router.pathname === '/';

    const handleAuth = React.useCallback(async () => {
      if (!isHandleAuth.current) {
        isHandleAuth.current = true;

        if (!authManager.isLoggedIn()) {
          sessionStorage.setItem('redirectTo', window.location.href);
          router.push('/');
          return;
        }
      }
    }, [authManager, router]);

    const handleHomePageAuth = React.useCallback(async () => {
      if (!isHandleAuth.current) {
        setLoading(true);
        isHandleAuth.current = true;

        if (authManager.isLoggedIn()) {
          router.push('/my-classes');
          setLoading(false);
          return;
        }
        setLoading(false);
      }
    }, []);

    React.useEffect(() => {
      if (isHomePage) {
        // home page will behave differently, if it logged in before, it will redirect to my-class
        // else just render the page
        handleHomePageAuth();
        return;
      }
      /* if current path is not public -> Checking authorized */
      if (!isPublicRoute) {
        handleAuth();
      }
    }, [handleAuth]);

    return (
      <SpokeSpinWrapper spinning={isLoading}>
        <AuthComponent {...props} auth={authManager} />
      </SpokeSpinWrapper>
    );
  };
